/*eslint-disable*/
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import AnimationCharacterMapList from '../components/AnimationCharacterMapList';
import Map from '../components/Map';
import NavPartage from '../components/NavPartage';
const headers = { Authorization:  localStorage.getItem('user') };

import '../css/MapList.scss';

class MapList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapsList: [],
      types: 'Toutes'
    };
    this.getMaps();
  }

  getMaps = () => {
    axios.get('/api/map', { headers }).then(response => {
      this.setState({
        mapsList: response.data.reverse()
      });
    });
  };

  render() {
    const { redirect, mapsList, types } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <Container className="mapList_container">
        <Row className="disconnect_row">
          {/*  <Link to='/mapcreation'>Création de carte</Link> */}
        </Row>
        <NavPartage />
        <Row className="mapList_row">
          <h1>Ykari</h1>
          <div className="">
            <p>
              Bienvenue à toi ! Tu es désormais un aventurier investi d’une
              grande mission. <br />
              Tu dois retrouver les petites flammes élémentaires, les Ykari,
              afin que l’arbre de vie, essentiel à l’équilibre de nos mondes
              retrouve sa force d’antan. <br />
              Avec le temps, de nombreux obstacles se dresseront sur ta route et
              tu devras alors prouver que c’est bien toi, l’élu.e de la
              prophétie. <br />
              Patience, d’autres épisodes vont arriver prochainement et te
              permettre d’accomplir ta destinée !{' '}
            </p>
          </div>

          <h2 className="">Nouveaux épisodes</h2>
          <div className="containerEpisode">
            <Row className="mapList_row">
              {mapsList
                .filter(
                  map =>
                    map.type === types || types === '' || types === 'Toutes'
                )
                .map((map, i) => (
                  <Map key={[i]} map={map} />
                ))}
            </Row>
          </div>
        </Row>
        <div className="animation">
          <AnimationCharacterMapList />
        </div>
      </Container>
    );
  }
}
MapList.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired
};

export default MapList;
