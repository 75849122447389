/* eslint-disable */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import '../css/Auth.scss';
import { Col, Row, Container, Input } from 'reactstrap';
import API from '../helpers/API';
import { withUser } from '../context/UserContext';
  import { v4 as uuidv4 } from "uuid";
class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:uuidv4(),
      pseudo: '',
      isAlreadySignedUp: false,
      redirect1: false,
      redirect2: false
    };
    this.handleChange.bind(this);

  }

  handleClick = e => {
    let duration = 0.3;
    let delay = 0.08;
    TweenMax.to(e.target, duration, { scaleY: 1.6, ease: Expo.easeOut });
    TweenMax.to(e.target, duration, {
      scaleX: 1.2,
      scaleY: 1,
      ease: Back.easeOut,
      easeParams: [3],
      delay: delay
    });
    TweenMax.to(e.target, duration * 1.25, {
      scaleX: 1,
      scaleY: 1,
      ease: Back.easeOut,
      easeParams: [6],
      delay: delay * 3
    });
  };


  sendNew = () => {
    if (this.state.pseudo.length === 0) {
      this.setState({ pseudo: 'guest'+this.state.id });
    }
    localStorage.setItem('user', this.state.pseudo);
this.props.userData === {
  pseudo: this.state.pseudo,
  id: this.state.id,
};;

    this.setState({ redirect2: true });
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckboxChange = e => {
    this.setState({ isAlreadySignedUp: !this.state.isAlreadySignedUp });
  };

  handleChangePage = () => {
    window.location = '/signup';
  };
  /*eslint-disable*/
  handle = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.sendNew();
    }
  };

  render() {
    const { email, password, pseudo, redirect1, redirect2 } = this.state;
    if (redirect1) {
      return <Redirect to='/' />;
    }
    if (redirect2) {
      return <Redirect to='/game' />;
    }
    return (
      <Container className='gameOver'>
        <Row className='gameOver-row-content'>
          <Col xs={12} md={6} lg={3}>
            <div className='sorry'>
              <fieldset>
                <legend>
                  Authentification
                </legend>



                  <FormGroup controlId='pseudo'>
                    <ControlLabel>Renseignez un pseudo</ControlLabel>
                    <FormControl
                      value={pseudo}
                      onChange={this.handleChange}
                      type='text'
                      placeholder=''
                      onKeyDown={this.handle}
                    />
                  </FormGroup>


                <FormGroup>

                    <Button
                      onClick={this.sendNew}
                      block
                      type='submit'
                      variant='link'
                      className='btn-primary'
                      onMouseOver={this.handleClick}
                    >
                      Jouer
                    </Button>


                </FormGroup>
              </fieldset>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withUser(Auth);
