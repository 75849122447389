/*eslint-disable*/
import React, { Component } from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Redirect } from 'react-router';
import '../css/healthBar.scss';

class HealthBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 100,
      ratioProgress: this.props.power,
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, this.state.ratioProgress * 100);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { safe, power } = this.props;

    if (safe === false) {
      // NOT IN THE FOYER
      const { percentage } = this.state;
      this.setState({ percentage: percentage - 1 });
    } else {
      // IN A SAFE AREA !! FOYER :D
      if (this.state.percentage !== 100) {
        this.setState({ percentage: 100, ratioProgress: power });
        this.componentWillUnmount();
        this.componentDidMount();
      }
    }
  };

  render() {
    const { percentage } = this.state;

    return (
      <div className="healthBar">
        {percentage < -1 ? <Redirect to="/gameover" /> : null}
        <CircularProgressbar
          className="circleProgB"
          percentage={percentage}
          // text={`${percentage}%`}
          counterClockwise
          // background={true}
          styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: 'rgba(367, 479)',
              // stroke: `rgba(245, 140, 0, ${percentage / 100})`,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',
              // Customize transition animation
              transition: `stroke-dashoffset ${this.state.ratioProgress * 0.1}s linear 0s`,
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: '#19343a',
            },
            // Customize the text
            text: {
              // Text color
              fill: '#f88',
              // Text size
              fontSize: '16px',
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: '#3e98c7',
            },
          }}
        />
      </div>
    );
  }
}

export default HealthBar;
