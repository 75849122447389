/*eslint-disable*/
import React, { Component } from 'react';
import '../css/Victory.scss';
import { TweenMax, Expo, Back } from 'gsap/TweenMax';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';



class Victory extends Component {
  handleClick = e => {
    const duration = 0.3;
    const delay = 0.08;
    TweenMax.to(e.target, duration, { scaleY: 1.6, ease: Expo.easeOut });
    TweenMax.to(e.target, duration, {
      scaleX: 1.2,
      scaleY: 1,
      ease: Back.easeOut,
      easeParams: [3],
      delay,
    });
    TweenMax.to(e.target, duration * 1.25, {
      scaleX: 1,
      scaleY: 1,
      ease: Back.easeOut,
      easeParams: [6],
      delay: delay * 3,
    });
  };

  render() {
    return (
      <Container className="victory">
        <Row className="victory-row-content">
          <Col xs={12} md={6} lg={5}>
            <div className="title">
              <h1>VICTORY</h1>
              <h2>Congratulations !</h2>
              <Row className="buttons-row">
                <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 0 }}>
                  <div className="button" onMouseOver={this.handleClick}>
                    <NavLink to="/">
                      <p>Go Home</p>
                    </NavLink>
                  </div>
                </Col>
                <Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 0 }}>
                  <div className="button" onMouseOver={this.handleClick}>
                    <NavLink to="/game">
                      <p>Play Again</p>
                    </NavLink>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Victory;
