/*eslint-disable*/
export default function generatePathGrid(map, walkableGrid, game, tilesOutput) {
  for (let y = 0; y < map.length; y += 1) {
    const col = [];
    for (let x = 0; x < map[y].length; x += 1) {
      if (
        tilesOutput.layerGround[`${x}-${y}`] &&
        tilesOutput.layerGround[`${x}-${y}`].isWalkable === true
      ) {
        col.push(1);
      } else {
        col.push(0);
      }
    }
    walkableGrid.push(col);
  }
  game.finder.setGrid(walkableGrid);
  game.finder.setAcceptableTiles([1]);
  // game.finder.enableDiagonals();
  // game.finder.disableCornerCutting();
}
