/* eslint-disable */
import { Scene } from 'phaser';
// import settingCameras from "../../helpers/utilsCameras";

export default class SceneAnimationCharacter extends Scene {
  constructor(config) {
    super({ key: 'SceneAnimationCharacter', active: true });
    this.config = config;
    /*eslint-disable*/
    this.character;
    this.frameNameCharacterMove;
  }

  preload() {
    this.load.multiatlas('character', 'assets/sitPlayer.json', 'assets');
  }
  create() {
    this.character = this.add.sprite(190, 155, 'character', '00001.png');
    this.character.setScale(0.5, 0.5);
    // settingCameras(this, this.character);

    this.frameNameCharacterMove = this.anims.generateFrameNames('character', {
      start: 0,
      end: 18,
      zeroPad: 5,
      // prefix: '',
      suffix: '.png',
    });

    this.anims.create({
      key: 'CharacterMove',
      frames: this.frameNameCharacterMove,
      frameRate: 10,
      repeat: -1,
    });

    this.character.anims.play('CharacterMove', true);
  }
}
