/* eslint-disable */

import React, { Component } from 'react';
import Phaser from 'phaser';
import MainScene from '../gameEngine/scenes/mainScene';
import layerGround from '../gameEngine/maps';
import HealthBar from '../components/HealthBar';
import '../css/Game.scss';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      safe: true,
      power: 1.7,
      audio: new Audio(),
    };
  }

  componentDidMount() {
    this.state.audio.src = '/audio/game.mp3';
    this.state.audio.volume = '0.8';
    this.state.audio.play();
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerHeight,
      // transparent: true,
      // backgroundColor: "#FFF5EE",
      scene: [
        new MainScene({
          map: {
            layerGround,
          },
          powerUp: () => {
            this.setState({ power: this.state.power + 1 });
          },
          setSafe: () => {
            this.setState({ safe: true });
          },
          setUnsafe: () => {
            this.setState({ safe: false });
          },
          victory: 4,
          victoryCallBack: () => {
            this.props.history.push('/victory');
          },
        }),
      ],
      parent: 'game',
    };
    new Phaser.Game(config);
  }
  componentWillUnmount() {
    this.state.audio.pause();
  }
  render() {
    const { safe, power } = this.state;

    return (
      <div className="gameInterface">
        {<div id="game" />}
        <div className="dark-layout" />
        <HealthBar safe={safe} power={power} />
      </div>
    );
  }
}

export default Game;
