/*eslint-disable*/
// Génération du player sur la map
import { coordToIso } from './UtilsCoord';
import Player from '../gameEngine/objectsPlayer/playerSprite';

export default function spawnHikari(positionIsoPlayer, size, game) {
  /*eslint-disable*/
  positionIsoPlayer = coordToIso(4, 4, size.x, size.y); // position du player
  const playerObject = new Player(
    game,
    positionIsoPlayer.x,
    positionIsoPlayer.y,
    'hikari',
    'hikari',
    'SE',
  );
  game.player = game.add.existing(playerObject);
  game.player.setScale(0.1, 0.1);
}
