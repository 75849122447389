/*eslint-disable*/
import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import imageMap from '../assets/map.png';
import imageMap2 from '../assets/map2.png';
import cardItems from '../assets/navBar/cardItems.png';
import axios from 'axios';
import { withPlayer } from '../context/PlayerContext';
import '../css/GodMap.scss';
const headers = { Authorization: 'Bearer ' + localStorage.getItem('token') };
class MapCreation extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            maps: []
        };
    }

    componentWillMount() {
        console.log("willmount")
        axios.get(`/api/map/user/me`, {
            headers
        })
            .then(res => this.setState({ maps: res.data }))
    }

    toggle(tab) {
        const { activeTab } = this.state;
        if ({ activeTab } !== tab) {
            this.setState({
                activeTab: tab,
                redirect: null
            });
        }
    }

    createMap = async () => {
        try {
            const response = await axios.post('/api/map', {}, { headers })
            console.log(response.data)
            this.setState({ redirect: `/gameEditor/${response.data._id}` })
        } catch (e) {
            console.log(e)
        }
    }

    /* className={{ factive: { activeTab } === '1' }} */
    /* eslint-disable */
    //bgDieux
    render() {
        const { maps, activeTab, redirect } = this.state;
        console.log(maps)
        if (redirect)
            return <Redirect to={redirect} />
        return (
            <div className="godmap">
            <Link to="/">Retour à la liste des cartes</Link>
                <Row>
                    <Col md="8">
                        <Row className="row-map">
                            <div className="linkAjoutCarte" onClick={this.createMap}>
                                <button type="button" className="ajoutCarte">
                                    <p className="signeAjout">+</p>
                                    Créer une carte
								</button>
                            </div>
                        </Row>
                        <Nav tabs className="navtabs">
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        this.toggle('2');
                                    }}
                                >
                                    Cartes Publiées
								</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                        this.toggle('1');
                                    }}
                                >
                                    Inventaire Cartes
								</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row className="bgDieux">
                                    <Col md="12" sm="12">
                                        <img className="imageMap" src={cardItems} alt="Une carte publiée" />

                                    </Col>
                                    <Col md="12" sm="12" >
                                        {maps.map((map, i) => <Link key={`map-${i}`} to={`/gameEditor/${map._id}`}><Button >{map.name}</Button></Link>)}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row className="bgDieux">
                                    <Col md="12" sm="12">
                                        <img className="imageMap" src={cardItems} alt="Une carte publiée" />

                                    </Col>
                                    <Col md="12" sm="12" >
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withPlayer(withRouter(MapCreation));
