import React, { Component } from 'react';
import Phaser from 'phaser';
import SceneAnimationCharacter from '../gameEngine/scenes/sceneAnimationCharacter';
/*eslint-disable*/
export default class AnimationCharacterMapList extends Component {
  componentDidMount() {
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: 250,
      transparent: true,
      scene: new SceneAnimationCharacter(),
      parent: 'animation',
    };
    new Phaser.Game(config);
  }

  render() {
    return <div id="animation" />;
  }
}
