import React from 'react';
import ReactDOM from 'react-dom';
/* eslint-disable */
import './css/theme/style.scss';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import FullscreenProvider from './context/FullscreenContext';

ReactDOM.render(
  <BrowserRouter>
    <FullscreenProvider>
      <App />
    </FullscreenProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.register();
