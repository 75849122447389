/*eslint-disable*/
// Méthodes de génération de la map du level
import { coordToIso } from './UtilsCoord';
import SpriteFabric from './UtilsSpriteFabric';

export default function spawnMap(game, map, tilesOutput, size, handleClick) {
  // Spawn layerGround

  for (let xx = 0; xx < map.layerGround.length; xx += 1) {
    for (let yy = 0; yy < map.layerGround[xx].length; yy += 1) {
      tilesOutput.layerGround[xx + '-' + yy] = new SpriteFabric(
        game,
        coordToIso(xx, yy, size.x, size.y).x,
        coordToIso(xx, yy, size.x, size.y).y,
        map.layerGround[xx][yy],
      );
      game.add.existing(tilesOutput.layerGround[xx + '-' + yy]);
      // Réglage Z-Index des tiles 'isNotWalkable'
      if (
        tilesOutput.layerGround[xx + '-' + yy].isWalkable === false &&
        tilesOutput.layerGround[xx + '-' + yy].name !== 'ground'
      )
        tilesOutput.layerGround[xx + '-' + yy].setDepth(10 * xx + yy);
      // Ajout de la surbrillance sur les cases 'isWalkable'
      if (tilesOutput.layerGround[xx + '-' + yy].isWalkable === true) {
        tilesOutput.layerGround[xx + '-' + yy].setInteractive();
        tilesOutput.layerGround[xx + '-' + yy].on('pointerdown', handleClick);
        tilesOutput.layerGround[xx + '-' + yy].on('pointerover', function() {
          this.setTint(0x86bfda);
        });
        tilesOutput.layerGround[xx + '-' + yy].on('pointerout', function() {
          this.clearTint();
        });
      }
    }
  }
}
