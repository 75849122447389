/* eslint-disable */
import { Scene } from 'phaser';
import Easystar from 'easystarjs';
import {
  settingCameras,
  spawnMap,
  // spawnPnj,
  generatePathGrid,
  isoToCoord,
  spawnHomeTree,
  spawnLittleHouse,
  spawnLostFire,
  spawnHikari,
} from '../../helpers';

export default class MainScene extends Scene {
  constructor(config) {
    super({ key: 'movePnjScene', active: true });
    this.config = config;
    // Grid Pathfinder
    this.walkableGrid = [];
    // Module Pathfinding
    this.finder = new Easystar.js();
    // Size of the tiles
    this.size = { x: 35, y: 35 };
    // Hikari
    this.player;

    this.gold;
    this.counterGold = 0;
    this.positionIsoPlayer;
    //
    this.tilesOutput = {
      layerGround: {},
      // layerDynamics: {},
      layerPnj: {},
    };
    this.isSafe = true;
    this.timeX = 0;
    this.timeY = 0;
    this.destination = new Phaser.Geom.Point(0, 0);
    this.isWalking = false;
    this.houses = [];
    this.triggers = [];
    this.victoryPrev = this.config.victory;
    this.newPNJ;
    this.music;
  }

  preload() {
    // Tiles Ground foret

    this.load.image('grass', '/image/grassTile.png'),
      this.load.image('droite', '/image/droite.png'),
      this.load.image('groundDale_bas', '/image/groundDale_bas.png'),
      this.load.image('groundDale_basDroite', '/image/groundDale_basDroite.png'),
      this.load.image('groundDale_basGauche', '/image/groundDale_basGauche.png'),
      this.load.image('groundDale_Haut', '/image/groundDale_Haut.png'),
      this.load.image('pillier', '/image/pillier.png'),
      this.load.image('angleBas', '/image/angle_bas.png'),
      this.load.image('droite', '/image/droite.png'),
      this.load.image('WallGauche', '/image/WallGauche.png'),
      this.load.image('WallHaut', '/image/WallHaut.png'),
      this.load.image('littleTree', '/image/littleTree.png'),
      //Tiles ground antique
      this.load.image('archeLeft', '/image/arche_left.png'),
      this.load.image('archeRight', '/image/arche_right.png'),
      this.load.image('groundAntique', '/image/ground.png'),
      this.load.image('ground3', '/image/ground3.png'),
      //Wall Antique
      this.load.image('wallDownRight', '/image/wallBasDroite.png'),
      this.load.image('wallDownLeft', '/image/wallBasGauche.png'),
      this.load.image('wallTopRight', '/image/wallHautDroite.png'),
      this.load.image('wallTopLeft', '/image/wallHautGauche.png'),
      // Bush Angle
      this.load.image('bush', '/images/bush.png'),
      // Stele elementaires
      this.load.image('steleFireOff', '/image/stele_off.png');
    this.load.image('steleAquaOff', '/image/stele_off.png');
    this.load.image('steleWingOff', '/image/stele_off.png');
    this.load.image('steleGroundOff', '/image/stele_off.png');
    this.load.image('steleFireOn', '/image/stele_fire_on.png');
    this.load.image('steleAquaOn', '/image/stele_aqua_on.png');
    this.load.image('steleWingOn', '/image/stele_wing_on.png');
    this.load.image('steleGroundOn', '/image/stele_ground_on.png');
    // Pnj
    this.load.multiatlas('aquaHikari', '/assets/aquaHikari.json', '/assets');
    this.load.multiatlas('fireHikari', '/assets/fireHikari.json', '/assets');
    this.load.multiatlas('wingHikari', '/assets/wingHikari.json', '/assets');
    this.load.multiatlas('groundHikari', '/assets/groundHikari.json', '/assets');
    //Hikari
    this.load.multiatlas('takka', '/assets/takka.json', '/assets');
    // Tree
    this.load.image('treeMagic', '/image/tree_magic.png');
    // Music
    this.load.audio('ambianceGame', 'audio/game.mp3');
  }

  create() {
    spawnMap(this, this.config.map, this.tilesOutput, this.size, this.handleClick);
    spawnHikari(this.positionIsoPlayer, this.size, this, this.counterGold);
    this.player.createAnimsPlayers(this);
    this.playerPrev = { x: this.player.x, y: this.player.y };
    spawnHomeTree(this.positionIsoHome, this.size, this);
    spawnLittleHouse(4, 2, this.size, this, 'Moe', 'steleAquaOff', false);
    spawnLittleHouse(4, 1, this.size, this, 'Homer', 'steleWingOff', false);
    spawnLittleHouse(1, 4, this.size, this, 'Bart', 'steleFireOff', false);
    spawnLittleHouse(2, 4, this.size, this, 'Marge', 'steleGroundOff', false);

    spawnLostFire(18, 1, this.size, this, 'Moe', 'aquaHikari');
    spawnLostFire(2, 9, this.size, this, 'Bart', 'fireHikari');
    spawnLostFire(18, 18, this.size, this, 'Homer', 'wingHikari');
    spawnLostFire(7, 15, this.size, this, 'Marge', 'groundHikari');

    generatePathGrid(this.config.map.layerGround, this.walkableGrid, this, this.tilesOutput);
    settingCameras(this);
  }

  update() {
    // Pos I / J du Player
    this.posPlayerI = isoToCoord(this.player.x, this.player.y, this.size.x, this.size.y).i;
    this.posPlayerJ = isoToCoord(this.player.x, this.player.y, this.size.x, this.size.y).j;
    // Update Z- Index Player
    this.player.setDepth(10 * this.posPlayerI + this.posPlayerJ + 0.5);
    this.player.updatePlayerOrientation(this);
    // Check if the player is in a safe zone
    if (
      this.player.x >= 70 * -1 &&
      this.player.x <= 70 * 1 &&
      this.player.y >= 40 * 1 &&
      this.player.y <= 40 * 4
    ) {
      if (this.isSafe === false) {
        this.isSafe = true;
        this.config.setSafe();
        if (this.config.victory <= 0) {
          this.config.victoryCallBack();
        }
        if (this.victoryPrev != this.config.victory) {
          this.config.powerUp();
          this.victoryPrev = this.config.victory;
        }
      }
    } else {
      if (this.isSafe === true) {
        this.isSafe = false;
        this.config.setUnsafe();
      }
    }
    // Look for an event trigger
    if (this.triggers[`${this.posPlayerI}-${this.posPlayerJ}`]) {
      let ret = this.triggers[`${this.posPlayerI}-${this.posPlayerJ}`].onTrigger();
      if (ret) {
        console.log(ret.texture.key);
        spawnLittleHouse(ret.i, ret.j, this.size, this, ret.name, ret.texture.key, true);
        this.config.victory--;
      }
      delete this.triggers[`${this.posPlayerI}-${this.posPlayerJ}`];
    }
  }

  handleClick() {
    // generatePathGridObstacle(this.scene.walkableGrid, this.scene, this.scene.tilesOutput);
    let from = isoToCoord(
      this.scene.player.x,
      this.scene.player.y,
      this.scene.size.x,
      this.scene.size.y,
    );
    let fromX = Math.trunc(from.i);
    let fromY = Math.trunc(from.j);

    let to = isoToCoord(this.x, this.y, this.scene.size.x, this.scene.size.y);
    let toX = Math.trunc(to.i);
    let toY = Math.trunc(to.j);

    this.scene.finder.findPath(fromX, fromY, toX, toY, path => {
      if (path === null) {
        console.warn('Path was not found.');
      } else {
        this.scene.player.moveCharacter(path, this.scene);
      }
    });
    this.scene.finder.calculate();
  }
}
