import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
};

export default {
  signin: data => axios.post(
    '/auth/signin',
    data,
    {
      headers,
    },
  ),
  signup: data => axios.post(
    '/auth/signup',
    data,
    {
      headers,
    },
  ),

  isAuth: () => localStorage.getItem('user') !== null,
  logout: () => {
    localStorage.clear();
  },
};
