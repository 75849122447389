/*eslint-disable*/
import Phaser from 'phaser';
import { cartesianToIsometric } from '../../helpers';

export default class Player extends Phaser.GameObjects.Sprite {
  constructor(
    scene,
    x,
    y,
    texture,
    name,
    // speed,
    direction,
    // gold
  ) {
    super(scene, x, y, texture, name, direction);
    // this.gold = gold;
    this.currentOrientation = direction;
  }

  updatePlayerOrientation = game => {
    const vect = new Phaser.Math.Vector2(this.x - game.playerPrev.x, this.y - game.playerPrev.y);
    if (vect.angle() > 0 && vect.angle() < Math.PI / 2) game.playerOrientation = 'SE';
    else if (vect.angle() > Math.PI / 2 && vect.angle() < Math.PI) game.playerOrientation = 'SO';
    else if (vect.angle() > Math.PI && vect.angle() < (3 * Math.PI) / 2)
      game.playerOrientation = 'NO';
    else if (vect.angle() > (3 * Math.PI) / 2 && vect.angle() < 2 * Math.PI)
      game.playerOrientation = 'NE';
    game.playerPrev = { x: this.x, y: this.y };

    if (vect.angle() === 0) {
      game.player.anims.play(`idle${this.currentOrientation}`, true);
    }
    if (game.playerOrientation === 'NO' && vect.angle() !== 0) {
      game.player.anims.play('NO', true);
    }
    if (game.playerOrientation === 'NE' && vect.angle() !== 0) {
      game.player.anims.play('NE', true);
    }
    if (game.playerOrientation === 'SE' && vect.angle() !== 0) {
      game.player.anims.play('SE', true);
    }
    if (game.playerOrientation === 'SO' && vect.angle() !== 0) {
      game.player.anims.play('SO', true);
    }
    this.currentOrientation = game.playerOrientation;
  };

  moveCharacter(path, scene) {
    let tweens = [];
    for (let i = 0; i < path.length - 1; i++) {
      let ex = path[i + 1].x;
      let ey = path[i + 1].y;
      let positionFromX = ex * scene.size.x;
      let positionFromY = ey * scene.size.y;
      let isoPt = cartesianToIsometric(positionFromX, positionFromY);

      tweens.push({
        targets: scene.player,
        x: { value: isoPt.x, duration: 300 },
        y: { value: isoPt.y, duration: 300 },
      });
    }
    scene.tweens.timeline({
      tweens: tweens,
    });
  }

  createAnimsPlayers(game) {
    const frameAnimationNordOuest = game.anims.generateFrameNames('takka', {
      start: 0,
      end: 6,
      zeroPad: 3,
      suffix: '.png',
    });
    const frameAnimationNordEst = game.anims.generateFrameNames('takka', {
      start: 7,
      end: 13,
      zeroPad: 3,
      suffix: '.png',
    });
    const frameAnimationSudEst = game.anims.generateFrameNames('takka', {
      start: 21,
      end: 28,
      zeroPad: 3,
      suffix: '.png',
    });
    const frameAnimationSudOuest = game.anims.generateFrameNames('takka', {
      start: 14,
      end: 20,
      zeroPad: 3,
      suffix: '.png',
    });
    const idleNO = game.anims.generateFrameNames('takka', {
      start: 0,
      end: 0,
      zeroPad: 3,
      suffix: '.png',
    });
    const idleNE = game.anims.generateFrameNames('takka', {
      start: 7,
      end: 7,
      zeroPad: 3,
      suffix: '.png',
    });
    const idleSO = game.anims.generateFrameNames('takka', {
      start: 14,
      end: 14,
      zeroPad: 3,
      suffix: '.png',
    });
    const idleSE = game.anims.generateFrameNames('takka', {
      start: 21,
      end: 21,
      zeroPad: 3,
      suffix: '.png',
    });

    game.anims.create({
      key: 'NO',
      frames: frameAnimationNordOuest,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'NE',
      frames: frameAnimationNordEst,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'SE',
      frames: frameAnimationSudEst,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'SO',
      frames: frameAnimationSudOuest,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'idleNO',
      frames: idleNO,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'idleNE',
      frames: idleNE,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'idleSO',
      frames: idleSO,
      frameRate: 10,
      repeat: -1,
    });
    game.anims.create({
      key: 'idleSE',
      frames: idleSE,
      frameRate: 10,
      repeat: -1,
    });
  }
}
