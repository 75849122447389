/*eslint-disable*/
import Phaser from 'phaser';

export default class HomeFire extends Phaser.GameObjects.Sprite {
  constructor(scene, x, y, texture, name, i, j) {
    super(scene, x, y, texture);
    this.name = name;
    this.i = i;
    this.j = j;
  }
}
