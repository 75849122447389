/*eslint-disable*/
// Génération du village
import { coordToIso } from './UtilsCoord';
import BigTree from '../gameEngine/objectsHome/bigTree';
import HomeFire from '../gameEngine/objectsHome/homeFire';
import PnjSprite from '../gameEngine/objectsLayerPnj/pnjSprite';

export function spawnHomeTree(positionIsoHome, size, game) {
  positionIsoHome = coordToIso(4, 4, size.x, size.y);
  const homeObject = new BigTree(
    game,
    positionIsoHome.x,
    positionIsoHome.y,
    'treeMagic',
    'treeMagic',
  );
  game.bigTree = game.add.existing(homeObject);
  game.bigTree.setDepth(10 * 3 + 3 + 0.2);
}

export function spawnLittleHouse(posI, posJ, size, game, name, element, enlighten) {
  switch (element) {
    case 'steleWingOff':
      if (enlighten === false) element = 'steleWingOff';
      else element = 'steleWingOn';
      break;
    case 'steleFireOff':
      if (enlighten === false) element = 'steleFireOff';
      else element = 'steleFireOn';
      break;
    case 'steleAquaOff':
      if (enlighten === false) element = 'steleAquaOff';
      else element = 'steleAquaOn';
      break;
    case 'steleGroundOff':
      if (enlighten === false) element = 'steleGroundOff';
      else element = 'steleGroundOn';
      break;
    default:
  }
  let positionIsoHouse = coordToIso(posI, posJ, size.x, size.y);
  const homeFire = new HomeFire(
    game,
    positionIsoHouse.x,
    positionIsoHouse.y,
    element,
    name,
    posI,
    posJ,
  );
  game.houses[`${name}`] = game.add.existing(homeFire);
  game.houses[`${name}`].setDepth(10 * posI + posJ + 0.2);
}

export function spawnLostFire(posI, posJ, size, game, name, texture) {
  let positionIsPNJ = coordToIso(posI, posJ, size.x, size.y);
  const pnj = new PnjSprite(
    game,
    positionIsPNJ.x,
    positionIsPNJ.y,
    texture,
    name,
    game.houses[`${name}`],
  );
  let newPNJ = game.add.existing(pnj);

  newPNJ.setDepth(10 * posI + posJ + 0.5);
  newPNJ.setScale(0.6, 0.6);
  game.triggers[`${posI}-${posJ}`] = pnj;
}
