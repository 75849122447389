/*eslint-disable*/
import Phaser from 'phaser';
import { cartesianToIsometric, isoToCoord } from '../../helpers';
export default class PnjSprite extends Phaser.GameObjects.Sprite {
  constructor(scene, x, y, texture, name, house) {
    super(scene, x, y, texture);
    this.name = name;
    this.house = house;
    // this.currentOrientation = direction;
  }

  onTrigger() {
    let from = isoToCoord(this.x, this.y, this.scene.size.x, this.scene.size.y);
    let fromX = Math.trunc(from.i);
    let fromY = Math.trunc(from.j);

    let to = isoToCoord(this.house.x, this.house.y, this.scene.size.x, this.scene.size.y);
    let toX = Math.trunc(to.i) + 1;
    let toY = Math.trunc(to.j) + 1;

    this.scene.finder.findPath(fromX, fromY, toX, toY, path => {
      if (path === null) {
        console.warn('Path was not found.');
      } else {
        this.movePnj(path, this.scene);
      }
    });
    this.scene.finder.calculate();
    return this.house;
  }

  movePnj(path, scene) {
    let tweens = [];
    for (let i = 0; i < path.length - 1; i++) {
      let ex = path[i + 1].x;
      let ey = path[i + 1].y;
      let positionFromX = ex * scene.size.x;
      let positionFromY = ey * scene.size.y;
      let isoPt = cartesianToIsometric(positionFromX, positionFromY);

      tweens.push({
        targets: this,
        x: { value: isoPt.x, duration: 300 },
        y: { value: isoPt.y, duration: 300 },
      });
      this.setDepth(10 * positionFromX + positionFromY + 0.5);
    }

    scene.tweens.timeline({
      tweens: tweens,
    });
  }
}
