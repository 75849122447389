/*eslint-disable*/
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import API from '../helpers/API';
import disconnect from '../assets/off-button.png';
import facebook from '../assets/facebook.png';
import insta from '../assets/instagram.png';
import { Row, Navbar } from 'reactstrap';
import '../css/NavPartage.scss';

class NavPartage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  disconnect = () => {
    API.logout();
    this.setState({ redirect: true });
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <Row className="disconnect_row">
        <Navbar className="disconnect_nav">
          <div className="disconnect_button">
            <a target="_blank" href="https://www.instagram.com/ykari.the.game/">
              <img className="instaIco" src={insta} alt="Instagram" />
            </a>
          </div>
          <div className="disconnect_button">
            <a target="_blank" href="https://www.facebook.com/Ykari-534414970404425/">
              <img className="facebookIco" src={facebook} alt="Facebook" />
            </a>
          </div>
          <div className="disconnect_button" onClick={this.disconnect}>
            <img className="disconnectIco" src={disconnect} alt="Déconexion" />
          </div>
        </Navbar>
      </Row>
    );
  }
}

export default NavPartage;
