/*eslint-disable*/
import GroundSprite from '../gameEngine/objectsLayerGround/groundSprite';
import ObstacleSprite from '../gameEngine/objectsLayerGround/obstacleSprite';
import PnjSprite from '../gameEngine/objectsLayerPnj/pnjSprite';

export default function SpriteFabric(game, x, y, json) {
  switch (json.type) {
    case 'GroundSprite':
      return new GroundSprite(game, x, y, json.args.texture, json.args.name, json.args.isWalkable);
    case 'ObstacleSprite':
      return new ObstacleSprite(
        game,
        x,
        y,
        json.args.texture,
        json.args.name,
        json.args.isWalkable,
      );
    case 'PnjSprite':
      return new PnjSprite(game, x, y, json.args.texture, json.args.name);
    default:
      return;
  }
}
