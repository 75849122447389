/*eslint-disable*/

const layerGround = [
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallHaut",
                "url": "/image/WallHaut.png",
                "name": "WallHaut",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallGauche",
                "url": "/image/WallGauche.png",
                "name": "WallGauche",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallGauche",
                "url": "/image/WallGauche.png",
                "name": "WallGauche",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallGauche",
                "url": "/image/WallGauche.png",
                "name": "WallGauche",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "droite",
                "url": "/image/droite.png",
                "name": "droite",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeLeft",
                "url": "/image/arche_left.png",
                "name": "archeLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "littleTree",
                "url": "/image/littleTree.png",
                "name": "littleTree",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeLeft",
                "url": "/image/arche_left.png",
                "name": "archeLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "littleTree",
                "url": "/image/littleTree.png",
                "name": "littleTree",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeRight",
                "url": "/image/arche_right.png",
                "name": "archeRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeLeft",
                "url": "/image/arche_left.png",
                "name": "archeLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "droite",
                "url": "/image/droite.png",
                "name": "droite",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeRight",
                "url": "/image/arche_right.png",
                "name": "archeRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeLeft",
                "url": "/image/arche_left.png",
                "name": "archeLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeRight",
                "url": "/image/arche_right.png",
                "name": "archeRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallGauche",
                "url": "/image/WallGauche.png",
                "name": "WallGauche",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeRight",
                "url": "/image/arche_right.png",
                "name": "archeRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "droite",
                "url": "/image/droite.png",
                "name": "droite",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "littleTree",
                "url": "/image/littleTree.png",
                "name": "littleTree",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "littleTree",
                "url": "/image/littleTree.png",
                "name": "littleTree",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "droite",
                "url": "/image/droite.png",
                "name": "droite",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "littleTree",
                "url": "/image/littleTree.png",
                "name": "littleTree",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "littleTree",
                "url": "/image/littleTree.png",
                "name": "littleTree",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeLeft",
                "url": "/image/arche_left.png",
                "name": "archeLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallGauche",
                "url": "/image/WallGauche.png",
                "name": "WallGauche",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallHaut",
                "url": "/image/WallHaut.png",
                "name": "WallHaut",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "archeRight",
                "url": "/image/arche_right.png",
                "name": "archeRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "pillier",
                "url": "/image/pillier.png",
                "name": "pillier",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopLeft",
                "url": "/image/wallHautGauche.png",
                "name": "wallTopLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "WallGauche",
                "url": "/image/WallGauche.png",
                "name": "WallGauche",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "groundAntique",
                "url": "/image/ground.png",
                "name": "groundAntique",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallTopRight",
                "url": "/image/wallHautDroite.png",
                "name": "wallTopRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "grass",
                "url": "/image/grassTile.png",
                "name": "grass",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "ground3",
                "url": "/image/ground3.png",
                "name": "ground3",
                "isWalkable": true
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownLeft",
                "url": "/image/wallBasGauche.png",
                "name": "wallDownLeft",
                "isWalkable": false
            }
        }
    ],
    [
        {
            "type": "GroundSprite",
            "args": {
                "texture": "droite",
                "url": "/image/droite.png",
                "name": "droite",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "wallDownRight",
                "url": "/image/wallBasDroite.png",
                "name": "wallDownRight",
                "isWalkable": false
            }
        },
        {
            "type": "GroundSprite",
            "args": {
                "texture": "angleBas",
                "url": "/image/angle_bas.png",
                "name": "angleBas",
                "isWalkable": false
            }
        }
    ]
  ]
  
  
export default layerGround;
