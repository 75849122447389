/* eslint-disable */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Fullscreen from 'react-full-screen';
import MapList from './pages/MapList';
import DetailMap from './pages/DetailMap';
import Auth from './pages/Auth';
import Game from './pages/Game';
import Victory from './pages/Victory';
import GameOver from './pages/GameOver';
import Signup from './pages/Signup';
import PrivateRoute from './components/PrivateRoute';
import UserProvider from './context/UserContext';
import PlayerProvider from './context/PlayerContext';
import GameProvider from './context/GameContext';
import { withFullscreen } from './context/FullscreenContext';
import MapCreation from './pages/MapCreation';
import GameEditor from './pages/GameEditor';
import './css/App.scss';
const App = ({ isFull }) => (
  <div className='App'>

    <Switch>
      <Route exact path='/authentication' component={Auth} />
      <UserProvider>
        <PlayerProvider>
          <GameProvider>
            <PrivateRoute exact path='/' component={MapList} />
            <PrivateRoute path='/map/:id' component={DetailMap} />
            <Fullscreen enabled={isFull}>
              <Route exact path='/game' component={Game} />
            </Fullscreen>
            <PrivateRoute exact path='/gameover' component={GameOver} />
            <PrivateRoute exact path='/victory' component={Victory} />
            <PrivateRoute exact path='/mapcreation' component={MapCreation} />
            <PrivateRoute exact path='/gameeditor/:id' component={GameEditor} />
          </GameProvider>
        </PlayerProvider>
      </UserProvider>
    </Switch>
  </div>
);

export default withFullscreen(App);
