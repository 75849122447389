/* eslint-disable */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { Container, Row, Col, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import MapImage from '../components/MapImage';
import MapDescriptif from '../components/MapDescriptif';
import MapComment from '../components/MapComment';
import CommentsPost from '../components/CommentsPost';
import { withRouter } from 'react-router';
import { withFullscreen } from '../context/FullscreenContext';
import NavPartage from '../components/NavPartage';

import '../css/DetailMap.scss';
const headers = { Authorization: 'Bearer ' + localStorage.getItem('token') };
class DetailMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      mapdetail: { userID: { pseudo: '' } }
    };
    this.getComments();
    this.getMap();
  }

  getMap = () => {
    const { match } = this.props;
    const url = `/api/map/${match.params.id}`;
    axios.get(url, { headers }).then(response => {
      this.setState({
        // ...this.state,
        mapdetail: response.data
      });
    });
  };

  getComments = () => {
    const { match } = this.props;
    const url = `/api/map/${match.params.id}/comments/`;
    axios.get(url, { headers }).then(response => {
      this.setState({
        // ...this.state,
        comments: response.data.reverse()
      });
    });
  };

  handleClick = e => {
    let duration = 0.3;
    let delay = 0.08;
    TweenMax.to(e.target, duration, { scaleY: 1.6, ease: Expo.easeOut });
    TweenMax.to(e.target, duration, {
      scaleX: 1.2,
      scaleY: 1,
      ease: Back.easeOut,
      easeParams: [3],
      delay: delay
    });
    TweenMax.to(e.target, duration * 1.25, {
      scaleX: 1,
      scaleY: 1,
      ease: Back.easeOut,
      easeParams: [6],
      delay: delay * 3
    });
  };

  render() {
    const { comments } = this.state;
    const { match } = this.props;
    const { mapdetail } = this.state;

    return (
      <Container className="detailMap">
        <NavPartage />
        <Row>
          <Col lg={{ size: 6, offset: 1 }} className="infoMapCol">
            <div className="completeMapDetail">
              {/* <MapBanner map={mapdetail} /> */}
              <MapImage map={mapdetail} />
              <MapDescriptif map={mapdetail} />
              <Button
                className="boutonPlay"
                onMouseOver={this.handleClick}
                onClick={() => {
                  // this.props.setFullscreen();
                  this.props.history.push(`/game`);
                }}
              >
                <div className="play">Jouer</div>
              </Button>
              <Button className="boutonPlay" onMouseOver={this.handleClick}>
                <div className="play">
                  <NavLink to="/">Retour</NavLink>
                </div>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

DetailMap.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(withFullscreen(DetailMap));
