/*eslint-disable*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Phaser, { Game } from 'phaser';
import {
    Container, Col, Row,
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup, Input, Label,
} from 'reactstrap';
import { Redirect } from 'react-router';

// import IsoInteractionExample from '../gameEngine/IsoInteractionExample';
import GameEditorScene from '../gameEngine/scenes/GameEditorScene';
import '../css/GameEditor.scss';
import axios from 'axios';
const headers = { Authorization: 'Bearer ' + localStorage.getItem('token') };


const imagesRaw = [
    // Assets
    { name: 'sea', url: '/images/tile_sea.png' },
    { name: 'sable', url: '/images/tile_sable.png' },
    { name: 'wall', url: '/images/tile_wall.png' },
    { name: 'wallLeft', url: '/images/tile_wallLeft.png' },
    { name: 'wallRight', url: '/images/tile_wallRight.png' },
    { name: 'woodTile', url: '/images/tile_woodGround.png' },
    { name: 'rock', url: '/images/tile_rock.png' },
    // Items null
    { name: 'null', url: '/images/null.png' },
];

const assetsRaw = [
    {
        "type": "GroundSprite",
        "args": {
            "texture": "ground3",
            url: '/image/ground3.png',
            "name": "ground3",
            "isWalkable": true
        }
    },


    // Tiles Ground foret

    {
        "type": "GroundSprite",
        "args": {
            "texture": "grass",
            url: '/image/grassTile.png',
            "name": "grass",
            "isWalkable": true
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "groundDaleBas",
            url: '/image/groundDale_bas.png',
            "name": "groundDaleBas",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "groundDaleBasDroite",
            url: '/image/groundDale_basDroite.png',
            "name": "groundDaleBasDroite",
            "isWalkable": false
        }
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "groundDaleBasGauche",
            url: '/image/groundDale_basGauche.png',
            "name": "groundDaleBasGauche",
            "isWalkable": false
        }
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "groundDaleHaut",
            url: '/image/groundDale_Haut.png',
            "name": "groundDaleHaut",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "pillier",
            url: '/image/pillier.png',
            "name": "pillier",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "angleBas",
            url: '/image/angle_bas.png',
            "name": "angleBas",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "droite",
            url: '/image/droite.png',
            "name": "droite",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "WallGauche",
            url: '/image/WallGauche.png',
            "name": "WallGauche",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "WallHaut",
            url: '/image/WallHaut.png',
            "name": "WallHaut",
            "isWalkable": false
        }
    },

    {
        "type": "GroundSprite",
        "args": {
            "texture": "littleTree",
            url: '/image/littleTree.png',
            "name": "littleTree",
            "isWalkable": false
        }
    },

    //Tiles ground antique
    {
        "type": "GroundSprite",
        "args": {
            "texture": "archeLeft",
            url: '/image/arche_left.png',
            "name": "archeLeft",
            "isWalkable": false
        }
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "archeRight",
            url: '/image/arche_right.png',
            "name": "archeRight",
            "isWalkable": false
        },
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "groundAntique",
            url: '/image/ground.png',
            "name": "groundAntique",
            "isWalkable": true
        },
    },

    //Wall Antique

    {
        "type": "GroundSprite",
        "args": {
            "texture": "wallDownRight",
            url: '/image/wallBasDroite.png',
            "name": "wallDownRight",
            "isWalkable": false
        }
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "wallDownLeft",
            url: '/image/wallBasGauche.png',
            "name": "wallDownLeft",
            "isWalkable": false
        }
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "wallTopRight",
            url: '/image/wallHautDroite.png',
            "name": "wallTopRight",
            "isWalkable": false
        }
    },
    {
        "type": "GroundSprite",
        "args": {
            "texture": "wallTopLeft",
            url: '/image/wallHautGauche.png',
            "name": "wallTopLeft",
            "isWalkable": false
        }
    },

    // Bush Angle
    {
        "type": "GroundSprite",
        "args": {
            "texture": "bush",
            url: '/image/bush.png',
            "name": "bush",
            "isWalkable": false
        }
    },


]

class GameEditor extends Component {
    constructor(props) {
        super(props);
        this.phaserContainer = React.createRef();
        this.game = null;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.state = {
            currentAsset: assetsRaw[0],
            preview: "",
            redirect: false,
            modalTitle: false,
            title: '',
            description: '',

        }
    }

    // Configuration de la game

    componentDidMount() {
        this.getGameMap();
    }
    getGameMap = () => {
        const { match } = this.props;
        const url = `/api/map/${match.params.id}`;
        console.log('url', url)
        axios.get(url, { headers }).then(response => {
            // const sceneLevelTmp = this.state.sceneLevel;
            // sceneLevelTmp.map.layerGround = response.data;
            // this.setState({
            //   sceneLevel: sceneLevelTmp,
            // });
            console.log(response.data)
            const { name, description, layerGround } = response.data;
            this.setState({
                title: name,
                description,
            })
            this.startGame(layerGround || this.generateDefaultMap());
        });
    }
    startGame = (layerGround) => {
        const config = {
            type: Phaser.AUTO,
            width: this.width,
            height: this.height,
            // pixelArt: true,
            // backgroundColor: '#808080',
            scene: [
                new GameEditorScene({
                    map: {
                        layerGround
                    },
                    assets: assetsRaw,
                }),
            ],
            // scene: new SceneGame({
            //   map: [
            //     [3, 3],
            //     [3, 12],
            //     [3, 12],
            //   ],
            // }),

            // scene: new sceneTest(),
            parent: 'game',
            render: {
                transparent: true,
                autoResize: true,
                preserveDrawingBuffer: true,

            },
            // scaleMode: Phaser.ScaleManager.RESIZE,
            // scaleMode: Phaser.ScaleManager ? Phaser.ScaleManager.RESIZE : false,

        };

        this.game = new Game(config);
    }

    generateDefaultMap = () => {
        const numsize = 20;
        const map = [];
        for (let i = 0; i < numsize; i++) {
            map[i] = [];
            for (let j = 0; j < numsize; j++) {
                map[i][j] = assetsRaw[0];
            }
        }
        return map;
    }

    selectAsset = (currentAsset) => {
        this.setState({ currentAsset })
        this.game.scene.getScene('scene-game').selectedTile = currentAsset;
        // console.log(this.game)
    }

    toggleModal = () => {
        const { modalTitle } = this.state;
        this.setState({ modalTitle: !modalTitle })
    }

    saveMap = async () => {
        const { match } = this.props;
        const { title, description } = this.state;
        const mapobj = {
            userID: "5c4aee21ed15340884c8988a",
            name: title,
            description,
            layerGround: this.game.scene.getScene('scene-game').map
        }

        console.log(this.game.scene.getScene('scene-game').map);
        console.log("canvas", this.game.canvas.toDataURL("image/png"))
        const preview = this.game.canvas.toDataURL("image/png");
        this.setState({ preview })
        const response = await axios.get(preview);
        const file = new File([response.data], 'preview.png', { type: 'image/png' });
        // axios.put(`/api/map/${match.params.id}`, mapobj, { headers })
        const reader = new FileReader();
        // console.log(mapobj);
        reader.onload = function () {
          const data = new FormData();
          data.append('preview', reader.result);
          // data.append('preview', '');
          data.append('map', JSON.stringify(mapobj));
          const result = axios.put(`/api/map/${match.params.id}`, data,{headers})
        };
        reader.readAsDataURL(file);


        // const reader = new FileReader();
        // reader.onloadend = async () => {

        //   try {
        //     console.log(`/api/map/${match.params.id}`)
        //   } catch (e) {
        //     console.log(e)
        //   }
        // }
        // reader.readAsDataURL(preview);
        // reader.readAsText(preview, "utf-8")

    }
    urltoFile(url, filename, mimeType) {
        return (axios.get(url)
            .then(function (buf) { console.log('buf', buf) })
        );
    }
    endMap = async () => {
        try {
            await this.saveMap();
            this.setState({ redirect: true })
        } catch (e) {

        }
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   this.setState({
        //     // preview: reader.result
        //     preview: file
        //   });
        // }
        // reader.readAsDataURL(file);
    }
    endAction = (map) => {
        console.log(map);
    }

    render() {
        const { currentAsset, preview, redirect, modalTitle, title, description } = this.state;
        if (redirect === true) {
            return <Redirect to="/mapcreation" />;
        }
        return (
            <div className="container ui">
                <img src={preview} />
                <div className="ui background" />
                <div ref={this.phaserContainer} id="game" />
                <div className="ui menu">
                    <GameMenuUI saveMap={this.saveMap} endMap={this.endMap} preview={preview} toggleModal={this.toggleModal} />
                </div>
                <div className="ui assets">
                    <GameAssetsUI selectAsset={this.selectAsset} assets={assetsRaw} currentAsset={currentAsset} />
                </div>
                <Modal isOpen={modalTitle} toggle={this.toggleModal} className={this.props.className}>
                    <ModalHeader toggle={this.toggleModal}>Informations</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="title">Titre</Label>
                            <Input type="text" name="email" id="title" placeholder="Entrer un titre" value={title} onChange={e => this.setState({ title: e.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Password</Label>
                            <Input type="textarea" name="password" id="description" placeholder="Entrer une description" value={description} onChange={e => this.setState({ description: e.target.value })} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleModal}>Valider</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default withRouter(GameEditor);

class GameAssetsUI extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // currentAsset: 0
        }
    }
    render() {
        const { selectAsset, assets, currentAsset } = this.props;
        const { } = this.state;
        console.log(currentAsset)
        return (
            <Container>
                <Row className='image_editeur'>
                    {
                        assets.map((asset, i) => (
                            <Col
                                key={`image-${i}`}
                                className={`asset ${currentAsset.args.texture === asset.args.texture ? 'active' : ''}`}
                                onClick={() => selectAsset(asset)}
                                style={{ backgroundImage: `url(${asset.args.url})` }} />
                        ))
                    }
                </Row>
            </Container>
        );
        GameAssetsUI.propTypes = {
            images: PropTypes.arrayOf(PropTypes.string).isRequired,
        };
    }
}

class GameMenuUI extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { saveMap, endMap, toggleModal } = this.props;
        return (
            <Container>
                <Row>
                    <Button color="danger" onClick={toggleModal}>Modifier titre</Button>
                </Row>
                <Row>
                    <Button onClick={saveMap}>Sauvegarder</Button>
                </Row>
                <Row>
                    <Button onClick={endMap}>Terminer</Button>
                </Row>

            </Container>
        )
    }
}
